// Common styles
.btn {
	background: #147dce;
	padding: 8px 50px 9px;
	color: #fff;
	font-size: 24px;
	font-family: 'Roboto';
	text-decoration: none;
	border-radius: 5px;
	transition: .3s ease-in-out;
	margin: 0 auto;
	border: 0;
	display: inline-block;

	&:hover {
		background: #fbb339;
		color: #fff;
	}

	&-white {
		background: #fff;
		color: #147dce;

		&:hover {
			background: #fbb339;
			color: #fff;
		}
	}

	&-trans {
		background: transparent;
		border: 2px solid #147dce;
		color: #fff;

		&:hover {
			background: #fbb339;
			border: 2px solid #fbb339;
		}
	}
}

h2 {
	text-align: center;
	color: #147dce;
	font-size: 32px;
	margin-bottom: 30px;
	font-weight: 400;
}

.up-btn {
	width: 70px;
	height: 70px;
	border-radius: 5px;
	border: 2px solid #147dce;
	position: fixed;
	bottom: 20px;
	right: 20px;
	transition: .3s ease-in-out;
	display: none;

	&:before {
		content: '';
		position: absolute;
		top: 27px;
		left: 21px;
		height: 25px;
		width: 25px;
		border-bottom: 2px solid #424344;
		border-left: 2px solid #424344;
		-webkit-transform: rotate(135deg);
		-moz-transform: rotate(135deg);
		-ms-transform: rotate(135deg);
		-o-transform: rotate(135deg);
		transform: rotate(135deg);
		transition: .3s ease-in-out;
	}

	&:hover {
		background: #147dce;

		&:before {
			border-color: #fff;
		}
	}
}

input, 
select {
	width: 100%;
	border: 1px solid #bababa;
	border-radius: 5px;
	padding: 11px;
	color: #161515;
	font-size: 15px;

	&.error {
		border: 1px solid #f00;
	}
}

.call-popup {
	border-radius: 10px;
	width: 100%;
	max-width: 350px;
	padding: 25px 15px;
	text-align: center;

	h2 {
		margin: 10px 0 20px;
	}

	.contact-form-item {
		padding-bottom: 20px;
	}
}

// Header
.header {
	box-shadow: 0 0 29px 0 rgba(13, 16, 20, 0.17);
	padding: 0 22px;
	position: fixed;
	background: #fff;
	z-index: 9;
	top: 0;
	width: 100%;

	&-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&-burger {
		display: none;
		width: 50px;
		height: 50px;
		padding-top: 20px;
		
		span {
			height: 4px;
			width: 100%;
			position: relative;
			background: #147dce;
			display: block;

			&:before {
				content: '';
				position: absolute;
				top: -10px;
				left: 0;
				width: 100%;
				height: 4px;
				background: #147dce;
				transition: .3s ease-in-out;
			}

			&:after {
				content: '';
				position: absolute;
				bottom: -10px;
				left: 0;
				width: 100%;
				height: 4px;
				background: #147dce;
				transition: .3s ease-in-out;
			}
		}

		&.active {
			span {
				background: transparent;

				&:before {
					top: 0;
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-ms-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
				}

				&:after {
					bottom: 0;
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-ms-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
			}
		}
	}

	&-mobile {
		width: calc(100% - 350px);
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	}

	&-logo a {
		display: flex;
		align-items: center;
		font-size: 12px;

		img {
			max-height: 110px;
		}
	}
	&-menu {
		ul {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		li {
			padding: 0 15px;
		}

		a {
			font-size: 24px;

			&:hover {
				border-bottom: 2px solid #ffab14;
				color: #161515;
			}
		}
	}
	&-lang {
		text-align: right;
	}
	&-phone {
		display: flex;
		align-items: center;

		a {
			font-size: 18px;
			display: block;
		}
	}
	.phone-icon {
		display: inline-block;
		width: 29px;
		height: 27px;
		vertical-align: middle;
		margin-right: 10px;
	}
	.site-name {
		display: block;
		font-size: 18px;
	}
	.site-subname {
		display: block;
		font-size: 12px;
	}
}

// Menu
.menu {
	background: #147dce;
	margin-top: 160px;

	&-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	ul {
		display: flex;
		align-items: center;
	}

	li {
		a {
			padding: 20px 23px;
			color: #fff;
			transition: .3s ease-in-out;
			display: inline-block;
			font-size: 20px;

			&:hover {
				background: #ffab14;
			}
		}
	}
}

// Main banner
.banner {
	background: url('../images/main-bg.jpg') no-repeat center/cover;

	&-driver {
		background: url('../images/driver-bg.jpg') no-repeat center/cover;
	}

	&-establishment {
		background: url('../images/establishment-bg.jpg') no-repeat center/cover;
	}

	&-driver,
	&-establishment {
		.banner-wrapper {
			display: block;
			max-width: 900px;
			margin: 0 auto;

			.bigger {
				font-size: 30px;
			}
		}
	}

	.next-screen {
		display: block;
		width: 50px;
		height: 40px;
		margin: 0 auto;
		position: relative;
		
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 25px;
			width: 25px;
			border-bottom: 2px solid #fff;
			border-left: 2px solid #fff;
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transform: rotate(-45deg);
			transition: .3s ease-in-out;
		}

		&:hover {
			&:before {
				border-color: #ffab14;
			}
		}	
	}

	.breadcrumbs {
		font-size: 20px;
		color: #fff;
		display: flex;
		align-items: center;
		li {
			padding-right: 5px;
		}
		img {
		    vertical-align: baseline;
		}
	}
	
	&-wrapper {	
		padding: 35px 0;
		color: #fff;
		font-size: 24px;
		line-height: 1.2;
		display: flex;
		justify-content: center;

		h1 {
			line-height: 1.5;
			font-weight: 400;
			font-size: 40px;
		}
	}

	&-left {
		max-width: 670px;
    	padding: 25px 80px 10px 33px;

    	p {
    		padding-bottom: 40px;
    	}
	}

	.order-form {
		background: #fff;
		padding: 25px 30px;
		border-radius: 10px;
		width: 350px;
		max-width: 100%;

		.form-field {
			display: block;
			width: 100%;
			padding-bottom: 8px;
		}

		.form-label {
			font-size: 18px;
			color: #1b1c1c;
			padding-bottom: 5px;
			display: block;
			font-family: 'Roboto';
		}
		
		.form-submit {
			padding-top: 5px;
		}

		input, 
		select {
			width: 100%;
			border: 1px solid #bababa;
			border-radius: 5px;
			padding: 11px;
			color: #161515;
			font-size: 15px;
		}

		.select-wrap {
			position: relative;
			cursor: pointer;
			
			select {
				cursor: pointer;
			}

			&:before {
				content: '';
				position: absolute;
				top: 10px;
    			right: 15px;
				width: 15px;
				height: 15px;
				border-bottom: 1px solid #a0a2a4;
				border-left: 1px solid #a0a2a4;
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}
		}

		.btn {
			font-size: 19px;
			padding: 12px 15px;
			width: 100%;
		}
	}
}

// Order type
.order {
	box-shadow: 0 0 29px 0 rgba(13, 16, 20, 0.17);
	padding: 10px 0 30px;
	margin-bottom: 60px;

	h2 {
		margin-bottom: 20px;
	}

	&-list {
		display: flex;
		justify-content: center;

		li {
			padding: 20px 60px;

			a {
				display: flex;
				align-items: flex-start;
				text-align: left;
				font-family: 'Roboto';
				font-size: 20px;
				line-height: 24px;
				color: #555556;

				&:hover {
					img {
						filter: invert(1);
					}
				}
			}

			p {
				margin: 0;
			}

			img {
				padding-right: 15px;
				transition: .3s ease-in-out;
			}

			&:not(:last-child) {
				border-right: 1px solid #b8babc; 
			}
		}
	}
}

// Problems
.problems {
	background: url('../images/dots-bg.png');
	padding: 10px 0 100px;
	text-align: center;

	&-list {
		display: flex;
		justify-content: space-around;
		padding-bottom: 50px;
		padding-top: 13px;
		margin: 0 auto;
    	max-width: 1080px;

		li {
			border: 1.5px solid #147dce;
			border-radius: 10px;
			padding: 45px 20px 15px;
			text-align: center;
			font-size: 14px;
			color: #4b4c4d;
			max-width: 240px;
			margin: 0 5px;
		}
	}

	&-icon {
		text-align: center;
		padding-bottom: 20px;
		margin-bottom: 20px;
		width: 100%;
		border-bottom: 1px solid #c6c6c6;
		display: block;
		height: 105px;
	}
}

// Advantages
.advantages {
	padding: 65px 0 100px;
	background: #147dce; 
	color: #fefefe;

	h2 {
		color: #fff;
	}

	&-wrapper {
		text-align: center;
	}

	&-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom: 5px;
		padding-top: 10px;
		margin: 0 auto;
    	max-width: 1160px;

		li {
			max-width: 30%;
			text-align: left;
			padding-bottom: 43px;
		}
	}

	&-icon {
		padding-bottom: 25px;
		display: block;
	}

	&-title {
		font-size: 20px;
		padding-bottom: 10px;
		display: block;
	}

	&-desc {
		font-size: 16px;
		display: block;
	}
}

// Work steps
.steps {
	text-align: center;
	margin-bottom: 40px;
	padding: 60px 0 95px;

	&-list {
		display: flex;
		justify-content: space-around;
		padding-bottom: 25px;

		li {
			text-align: center;
			font-size: 20px;
			line-height: 24px;
			color: #262628;
			display: flex;
			flex-direction: column;
			max-width: 270px;

			&:not(:last-child) {
				position: relative;
				
				&:before {
					content: '';
					position: absolute;
					top: 20px;
					left: 75%;
					height: 8px;
					width: 100%;
					background: url('../images/dots.png');
					z-index: -1;
				}
			}
		}
	}

	&-number {
		display: inline-block;
		margin: 0 auto;
		font-weight: 700;
		font-size: 48px;
		color: #147dce;
		padding: 18px;
		border: 5px solid #147dce;
		border-radius: 10px;
		margin-bottom: 50px;
		background: #fff;
	}

	&-icon {
		display: block;
		margin-bottom: 7px;
	}
}

// Sales
.sales {
	background: url('../images/blue-bg.jpg') no-repeat center/cover;
	text-align: center;
	padding: 50px 0;

	&-wrapper {
		max-width: 1300px;
	}

	&-slider {
		margin: 0 auto;

		.sales-slide {
			position: relative;
		}

		.slide-image {
			max-width: 880px;
    		margin: 0 auto;
		}

		.slide-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 40px;
			max-width: 600px;
			width: auto;
			color: #fff;
		}
	}
}

// Slick styles
.slick {
	&-prev,
	&-next {
		height: 40px;

		&:before {
			content: '';
			position: absolute;
			top: 10px;
			width: 25px;
			height: 25px;
			border-bottom: 2px solid #6d6f70;
			border-left: 2px solid #6d6f70;
		}
	}
	&-prev {
		left: 25px;
		
		&:before {
			left: 5px;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
	&-next {
		right: 25px;

		&:before {
			right: 5px;
			-webkit-transform: rotate(-135deg);
			-moz-transform: rotate(-135deg);
			-ms-transform: rotate(-135deg);
			-o-transform: rotate(-135deg);
			transform: rotate(-135deg);
		}
	}
}

// Services
.services {
	&-wrapper {
		text-align: center;
	}

	&-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		
		li {
			box-shadow: 0 4px 24px 0px #ccc;
			border-radius: 10px;
			width: 31%;
			margin-bottom: 25px;
			overflow: hidden;
			position: relative;
			text-align: left;
		}
	}

	&-details,
	&-info {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		width: 90%;
		height: 90%;
		border-radius: 10px;
	}

	&-details {
		background: #fff;
		padding: 20px 15px 15px;
		color: #232424;
		font-size: 14px;
		opacity: 0;
		transition: .3s ease-in-out;
		text-align: center;
		
		span {
			font-size: 20px;
			color: #147dce;
			padding-bottom: 10px;
		}

		&.active {
			opacity: 1;
			z-index: 9;
		}
	}

	&-title {
		font-size: 20px;
		color: #147dce;
		padding-bottom: 10px;
	}

	&-info {
		background: rgba(8, 30, 75, .5);
		padding: 25px 20px 20px;
		text-align: center;

		.services-title {
			font-size: 30px;
			color: #feffff;
			padding-bottom: 10px;
			display: block;
		}

		.btn {
			font-size: 19px;
			margin: 0 auto 10px;

			&-trans {
				margin-top: 20px;
			}
		}
	}

	&-icon {
		img {
			width: 100%;
    		height: 100%;
    		object-fit: cover;
		}
	}

	&-time {
		display: block;
		color: #fff;
		text-align: center;
		font-size: 16px;
	}
}

// Reasons to order driver
.reasons {
	padding: 40px 0;

	&-list {
		margin: 0 auto;
		max-width: 1000px;

		li {
			font-size: 20px;
			color: #1f1e1e;
			padding-bottom: 40px;
		}
	}

	&-icon {
		float: left;
		vertical-align: middle;
		margin-right: 15px;
		width: 150px;
	}

	&-title {
		font-size: 30px;
		color: #1f1e1e;
		padding-bottom: 15px;
		width: calc(100% - 175px);
		display: inline-block;
	}

	&-desc {
		width: calc(100% - 175px);
		display: inline-block;
	}
}

// FAQ
.faq {
	background: url('../images/dark-blue-bg.jpg') no-repeat center/cover;
	padding: 40px 0;

	h2 {
		color: #fff;
	}

	&-list {
		width: 100%;
		max-width: 770px;
		margin: 0 auto;

		li {
			background: #fff;
			border-radius: 10px;
			margin-bottom: 20px;
		}
	}

	.dropdown {
		display: none;
		padding: 10px 40px 30px;
	}

	.toggle-dropdown {
		font-size: 24px;
		color: #222121;
		display: block;
		padding: 35px 40px;
		position: relative;
		text-align: left;
		
		&:before {
			content: '';
			position: absolute;
			width: 25px;
			height: 25px;
			right: 40px;
			top: 30px;
			border-bottom: 2px solid #6d6f70;
			border-left: 2px solid #6d6f70;
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}

		&.open {
			&:before {
				top: 45px;
				-webkit-transform: rotate(135deg);
				-moz-transform: rotate(135deg);
				-ms-transform: rotate(135deg);
				-o-transform: rotate(135deg);
				transform: rotate(135deg);
			}
		}
	}
}

// Reviews
.reviews {
	padding: 40px 0;

	&-slider {
		background: url('../images/quotes.png') no-repeat center/contain;
	}

	&-slide {
		text-align: center;
	}

	&-text {
		max-width: 600px;
		font-size: 18px;
		color: #515253;
		padding-bottom: 40px;
		margin: 0 auto;
	}

	&-image {
		border-radius: 50%;
		border: 2px solid #147dce;
		overflow: hidden;
		width: 145px;
		height: 145px;
		margin: 0 auto;
		
		img {
			min-height: 100%;
			min-width: 100%;
			object-fit: cover;
		}
	}

	.slick-prev {
		left: 100px;
	}

	.slick-next {
		right: 100px;
	}
}

// Question form
.question {
	padding: 40px 0;
	background: url('../images/questions-bg.jpg') no-repeat center/cover;

	&-desc {
		max-width: 550px;
		font-size: 20px;
		color: #555454;
		padding-bottom: 20px;
		margin: 0 auto;
		text-align: center;
	}

	&-label {
		font-size: 24px;
		color: #111010;
		padding-bottom: 15px;
	}

	&-form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&-field {
		width: 47%;
		padding-bottom: 20px;

		&.full-width {
			width: 100%;
		}
	}

	input {
		font-size: 18px;
		color: #989393;
		background: #fff;
		padding: 40px 35px 37px;
		border-radius: 10px;
		border: 2px solid #959595;
		width: 100%;

		&[type="checkbox"] {
			width: 12px;
			height: 12px;
			border-radius: 2px;
			border: 1px solid #959595;
			background: transparent;
			padding: 0;
			margin-right: 10px;
			cursor: pointer;

			&:checked {
				background: #959595;
			}

			& + label {
				font-size: 18px;
				color: #989393;
				cursor: pointer;
			}
		}
	}

	.form-submit {
		margin: 0 auto;
	}
}

// Footer
.footer {
	background: #147dce;
	color: #fff;
	padding: 48px 0;

	&-wrapper {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding-bottom: 15px;
	}

	&-logo {
		display: flex;
		align-items: center;
		font-size: 12px;
		padding-bottom: 20px;

		img {
			max-height: 125px;
			padding-right: 15px;
		}
	}

	&-social {
		padding-bottom: 15px;
	}

	li {
		padding-bottom: 15px;
	}

	a {
		font-size: 20px;
		color: #fff;

		&:hover {
			color: #ffab14;
		}
	}

	&-phone {
		display: flex;
		align-items: center;

		a {
			font-size: 18px;
			display: block;
			padding-bottom: 10px;
		}
	}
	.phone-icon {
		display: inline-block;
		width: 29px;
		height: 27px;
		vertical-align: middle;
		margin-right: 10px;

		img {
		 	filter: brightness(0) invert(1);
		}
	}
	.site-name {
		display: block;
		font-size: 18px;
	}
	.site-subname {
		display: block;
		font-size: 12px;
	}

	.copyright {
		font-size: 16px;
	}
}
